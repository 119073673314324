@media (min-width:280px) and (max-width:479px)

  .area-site
    padding: 32px

  .image-effect
    img
      transform: scale(1.1) translateY(40px)

  #banner-home
    margin: 0 0 0px
    h2
      div
        height: 40px
        margin-bottom: 0

    .area-banner .area-filter
      margin-top: 32px
      .filter-itens
        p
          font-size: 12px
          line-height: 18px
        
        li button
          margin: 0 10px
  .home
    #slider
      margin: 40px 0 0px
    footer.footer
      padding: 0
    h3
      font-size: 12px

  .site
    .border-line, .border-top, .border-bottom
      display: none

    .paddImg
      margin: 40px 0
  p
    font-size: 16px
    line-height: 20px
  
  p.text-featured
    font-size: 16px
    line-height: 24px

  .area-footer h3
    font-size: 12px

  h2
    font-size: 34px
    line-height: 40px

  h3
    font-size: 16px
    line-height: 24px

  h4
    font-size: 16px
    line-height: 20px

  h5
    font-size: 40px
    line-height: 52px

  blockquote p
    font-size: 18px
    line-height: 28px

  .quote-icon
    height: 24px
    top: 24px
    left: 0

  header
    height: 72px
    &.header-home
      height: 92px

    .logo
      left: 0
      top: 8px
      width: 90px 

    .navbar-toggler
      position: absolute
      right: 0
      top: 10px
      height: 16px

      .bar
        display: block
        position: absolute
        transition: 0.3s ease-in-out all

        &.bar-item-one
          width: 24px
          height: 3px
          top: 0
          right: 0

        &.bar-item-two
          width: 16px
          height: 3px
          top: auto
          bottom: 0
          right: 0
  
    &.active
      .navbar-toggler
        position: fixed
        top: 42px
        right: 34px
        .bar
          &.bar-item-one
            transform: rotate(45deg) translate(9px, 0px)

          &.bar-item-two
            width: 24px
            transform: rotate(-45deg) translate(9px, -1px)

  #menu
    .area-menu
      padding: 40px 32px 0
      overflow-y: scroll
    .embla__slide
      min-width: 184px
      &:first-child
        margin-left: 32px
      h2
        font-size: 22px
        line-height: 30px
    .embla__slide__inner
      height: 216px
    .embla__slide__img
      max-height: 280px
    .embla__progress
      left: 0
      width: calc(100% - 64px)
    
    #projects-menu
      overflow: hidden
      width: calc(100% + 64px)
      left: -32px
      position: relative
      max-width: none

    .footer-menu
      position: relative
      bottom: 0px
      width: 100%
      .squares
        margin-top: 40px
      ul
        display: block
        p
          width: 100%
          margin: 40px 0
          
        li
          margin: 0
          padding: 0
          width: 100%
          display: block
          &:nth-child(2)
            text-align: left
          &:nth-child(3)
            text-align: left


  .about, .home
    .border-bottom
      border: none
  #about
    .area-menu
      padding: 40px 32px 0
      overflow-y: scroll
    .embla__slide
      min-width: 184px
      &:first-child
        margin-left: 32px
      h2
        font-size: 22px
        line-height: 30px
    .embla__slide__inner
      height: 216px
    .embla__slide__img
      max-height: 280px
    .embla__progress
      left: 0
      width: calc(100% - 64px)
    .area-about
      min-height: 2470px
      &::before
        width: 100%
        left: 0
        top: 170px
        height: 2050px
      &:after
        display: none
      #team-area
        margin: 0 auto
        width: 60%
        flex-wrap: wrap
        .item-team
          width: 100%
          margin: 30px 0 0
          p
            small
              letter-spacing: 2.4px
              display: block
              margin: 0 auto
              max-width: auto
          h6
            font-size: 20px
            max-width: 100%
            margin: 5px auto 0
            line-height: 24px
      #team-area-2
        margin: 0 auto
        width: 60%
        flex-wrap: wrap
        .item-team
          width: 100%
          margin: 30px 0 0
          p
            small
              letter-spacing: 2.4px
              display: block
              margin: 0 auto
              max-width: auto
          h6
            font-size: 20px
            max-width: 100%
            margin: 5px auto 0
            line-height: 24px

    #header-Featured
      flex-wrap: wrap
      margin-bottom: 0
      .area-column
        margin: 40px 0
        &.title-area
          width: 100%

    .container.full-image
      max-width: 100%

    #imageFeaturedProfile
      width: calc(100% + 64px)
      position: relative
      left: -32px
      bottom: 0

      h6
        margin: 20px 0 0

    #projects
      padding-left: 32px
      width: 100%
      overflow: hidden
      width: calc(100% + 64px)
      left: -32px
      
      #projects-about
        overflow: hidden
        width: calc(100% + 64px)
        left: -32px
        position: relative
        max-width: none

      .footer-menu
        position: relative
        bottom: 0px
        width: 100%
        .squares
          margin-top: 40px
        ul
          display: block
          p
            width: 100%
            margin: 40px 0
            
          li
            margin: 0
            padding: 0
            width: 100%
            display: block
            &:nth-child(2)
              text-align: left
            &:nth-child(3)
              text-align: left

  section
    margin: 40px 0 0

  #slider
    .area-slider
      .step-portfolio
        height: 130px
        width: 130px
        bottom: -80px
        right: 40px
      .item-area
        margin-bottom: 40px
        height: auto
        img
          margin-top: 0
          width: 101%

    .container.full-image
      width: 100%
      max-width: 100%

  #testimonial
    .embla
      max-width: 100%
      width: 100%
    
    .embla__dots
      max-width: none

    .area-testimonial
      margin: 0 auto
      left: -5px
      
      figure
        padding: 16px 0 16px 34px

        figcaption
          font-size: 12px
          line-height: 16px

    u:after
      bottom: 4px
      height: 4px

  #about

    h5
      font-size: 24px
      line-height: 32px
      padding: 0
      margin: 0

    .area-text
      padding: 40px 32px

    .container.full
      left: -32px
      position: relative
      width: calc(100% + 64px)
      max-width: calc(100% + 64px)

    .area-flutuante
      padding: 0
      position: relative
      right: auto
      float: none
      margin: 0 auto -100px 0
      width: calc( 100% )
      z-index: 0

      &::before, &:after
        display: none

  #internal-page
    #imageFeaturedProfile
      width: calc(100% + 64px)
      position: relative
      left: -32px
      bottom: 0

      .container.full-image
        max-width: none
        margin: 0
        left: 0
        position: relative
        
      .area-text
        padding: 0 32px
    
    p.text-subtite
      line-height: 24px

    h2.subtitle
      div
        height: 30px

  #internal-page h2, h2.subtitle, #menu h5
    font-size: 24px
    line-height: 32px
  
  #internal-page h3, h3.subtitle
    font-size: 16px
    line-height: 24px
    color: #606065
      
  .float-image
    position: relative
    img
      max-width: 100%

  .float-image.image-right
    top: 0
    transform: none
    right: 0
    max-width: calc(100% + 32px)

  .area-black .imagesProfile.float-left
    padding-top: 70px

  .area-black .imagesProfile.float-left .container
    max-width: calc(100% + 32px)
    margin: 0
    left: 0
    position: relative

    img
      width: 100%

  .imagesProfile.float-left .container.full
    width: calc(100% - 32px)
    left: 0

  .float-image.image-left
    top: 0
    transform: none
    left: 0
    max-width: calc(100% + 32px)
    margin-bottom: 32px

  .imagesProfile .container.full
    max-width: calc(100% + 32px)
    margin: 26px 0px 160px
    .area-image
      left: -32px
      position: relative
      margin: 0
      width: calc(100% + 64px)
      img
        width: calc(100% + 64px)
        max-width: none


  .content-profile
    padding: 40px 0

    .area-text
      padding: 0

    .step-portfolio
      width: 116px
      height: 74px

    &.step-one .step-portfolio
      transform: translate(-20px, -45px)

    &.step-two .step-portfolio
      transform: translate(-20px, -45px)

    &.step-three 
      padding: 200px 0 40px
      .step-portfolio
        transform: translate(-20px, -45px)

  .area-black.float-area
    padding: 40px 32px 0
    width: calc(100% + 64px)
    left: -32px
    margin-top: -279px

  .column
    --width-tablet: var(--width-mobile)
    --width: var(--width-mobile)
  
  .container, .container.full 
    max-width: 100%

  footer.footer
    position: relative
    bottom: 0px
    width: 100%
    .squares
      margin-top: 40px
    ul
      display: block
      p
        width: 100%
        margin: 40px 0
        
      li
        margin: 0
        padding: 0
        width: 100%
        display: block
        &:nth-child(2)
          text-align: left
        &:nth-child(3)
          text-align: left

  .area-footer
    h3
      font-style: normal
      font-weight: 700
      font-size: 16px
      line-height: 20px
      text-align: center
      letter-spacing: 0.4em
      text-transform: uppercase



@media (min-width: 375px) and (max-width: 399px)

  #about
    .area-about
      min-height: 2420px
      &::before
        height: 2010px
      #team-area
        margin: 0 auto
        width: 50%
      #team-area-2
        margin: 0 auto
        width: 50%
        .item-team
          width: 100%
          margin: 30px 0 0

  .area-footer
    h3
      font-style: normal
      font-weight: 700
      font-size: 16px
      line-height: 20px
      text-align: center
      letter-spacing: 0.4em
      text-transform: uppercase

@media (min-width: 400px) and (max-width: 420px)

  #about
    .area-about
      min-height: 2420px
      &::before
        height: 2000px
      #team-area
        margin: 0 auto
        width: 45%
      #team-area-2
        margin: 0 auto
        width: 45%
        .item-team
          width: 100%
          margin: 30px 0 0

  .area-footer
    h3
      font-style: normal
      font-weight: 700
      font-size: 16px
      line-height: 20px
      text-align: center
      letter-spacing: 0.4em
      text-transform: uppercase

  
@media (min-width:480px) and (max-width:599px)
  .column
    --width-tablet: var(--width-mobile)
    --width: var(--width-tablet)
  
@media (min-width:600px) and (max-width:800px)
  .column
    --width-desktop: var(--width-tablet)
    --width: var(--width-desktop)
  
@media (min-width:801px) and (max-width:1024px)
  .column
    --width-desktop: var(--width-tablet)
    --width: var(--width-desktop)

@media (min-width:1900px)
  .area-site
    .area-black.float-area
      padding: 240px 50px 0 80px

@media (min-width:480px) and (max-width:1024px)

  .area-site
    padding: 32px
    .area-black.float-area
      padding: 240px 50px 0 80px

  .image-effect
    // &.image-delay
    //   img, &::before
    //     transition-delay: 1.2s
    // &.image-delay-1
    //   img, &::before
    //     transition-delay: 0.5s
    img
      transition: all ease-in-out 1.6s
      transform: translate3d(0, 100px, 0)
    &:before
      transition: all ease-in-out 1.6s

    &.active
      &:before
        transform: translate3d(0, -100%, 0)
      img
        transform: translate3d(0, 0, 0)

  .area-footer
    h3
      font-style: normal
      font-weight: 700
      font-size: 16px
      line-height: 20px
      text-align: center
      letter-spacing: 0.4em
      text-transform: uppercase
  
  #banner-home
    h2
      div
        height: 72px
        margin-bottom: 0

  #slider
    margin: 40px 0 80px

  .site
    .border-line, .border-top, .border-bottom
      display: none
  p
    font-size: 16px
    line-height: 20px
  
  p.text-featured
    font-size: 16px
    line-height: 24px

  h2
    font-size: 64px
    line-height: 80px

  h3
    font-size: 24px
    line-height: 32px

  h4
    font-size: 16px
    line-height: 20px

  h5
    font-size: 40px
    line-height: 52px

  blockquote p
    font-size: 18px
    line-height: 28px

  .quote-icon
    height: 24px
    top: 24px
    left: 0

  header
    height: 72px
    &.header-home
      height: 92px

    .logo
      left: 0
      top: 8px
      width: 90px 

    .navbar-toggler
      position: absolute
      right: 0
      top: 10px
      height: 16px

      .bar
        display: block
        position: absolute
        transition: 0.3s ease-in-out all

        &.bar-item-one
          width: 24px
          height: 3px
          top: 0
          right: 0

        &.bar-item-two
          width: 16px
          height: 3px
          top: auto
          bottom: 0
          right: 0
  
    &.active
      .navbar-toggler
        position: fixed
        top: 42px
        right: 34px
        .bar
          &.bar-item-one
            transform: rotate(45deg) translate(9px, 0px)

          &.bar-item-two
            width: 24px
            transform: rotate(-45deg) translate(9px, -1px)

  #menu
    .area-menu
      padding: 40px 32px 0
      overflow-y: scroll
    .embla__slide
      min-width: 280px
      &:first-child
        margin-left: 32px
      h2
        font-size: 22px
        line-height: 30px
    .embla__slide__inner
      height: 280px
    .embla__slide__img
      max-height: 280px
    .embla__progress
      left: 0
      width: calc(100% - 64px)
    
    #projects-menu
      overflow: hidden
      width: calc(100% + 64px)
      left: -32px
      position: relative
      max-width: none

    .footer-menu
      position: relative
      bottom: 0px
      width: 100%
      .squares
        margin-top: 40px
      ul
        display: block
        p
          width: 100%
          margin: 40px 0
          
        li
          margin: 0
          padding: 0
          width: 100%
          display: block
          &:nth-child(2)
            text-align: left
          &:nth-child(3)
            text-align: left


  .about, .home
    .border-bottom
      border: none
  #about
    .area-menu
      padding: 40px 32px 0
      overflow-y: scroll
    .embla__slide
      min-width: 280px
      &:first-child
        margin-left: 32px
      h2
        font-size: 22px
        line-height: 30px
    .embla__slide__inner
      height: 280px
    .embla__slide__img
      max-height: 280px
    .embla__progress
      left: 0
      width: calc(100% - 64px)
    .area-about
      min-height: 700px
      &::before
        width: 100%
        left: 0
        top: 170px
        height: 780px
      &:after
        display: none
      #team-area
        margin: 10px 32px
        flex-wrap: wrap
        .item-team
          width: 25%
          margin-bottom: 20px
          p
            small
              letter-spacing: 2.4px
              display: block
              margin: 0 auto
              max-width: 120px
          h6
            font-size: 20px
            max-width: 100px
            margin: 5px auto 0
            line-height: 24px

    #header-Featured
      flex-wrap: wrap
      margin-bottom: 0
      .area-column
        margin: 40px 0
        &.title-area
          max-width: 370px

    .container.full-image
      max-width: 100%

    #imageFeaturedProfile
      width: calc(100% + 64px)
      position: relative
      left: -32px
      bottom: 0

      h6
        margin: 20px 0 0

    #projects
      margin-top: 35px
      padding-left: 32px
      width: 100%
      overflow: hidden
      width: calc(100% + 64px)
      left: -32px
      
      #projects-about
        overflow: hidden
        width: calc(100% + 64px)
        left: -32px
        position: relative
        max-width: none

      .footer-menu
        position: relative
        bottom: 0px
        width: 100%
        .squares
          margin-top: 40px
        ul
          display: block
          p
            width: 100%
            margin: 40px 0
            
          li
            margin: 0
            padding: 0
            width: 100%
            display: block
            &:nth-child(2)
              text-align: left
            &:nth-child(3)
              text-align: left

  #banner-home
    margin: 0 0 80px

  section
    margin: 40px 0 0

  #slider
    .area-slider
      .step-portfolio
        height: 130px
        width: 130px
        bottom: -80px
        right: 40px
      .item-area
        margin-bottom: 40px
        height: 480px
        img
          margin-top: 0
          width: 101%

    .container.full-image
      width: 100%
      max-width: 100%

  #testimonial
    .embla
      max-width: 100%
      width: 100%
    
    .embla__dots
      max-width: none

    .area-testimonial
      margin: 0 auto
      left: -5px
      
      figure
        padding: 16px 0 16px 34px

        figcaption
          font-size: 12px
          line-height: 16px

    u:after
      bottom: 4px
      height: 4px

  #about

    h5
      font-size: 24px
      line-height: 32px
      padding: 0
      margin: 0

    .area-text
      padding: 40px 32px

    .container.full
      left: -32px
      position: relative
      width: calc(100% + 64px)
      max-width: calc(100% + 64px)

    .area-flutuante
      padding: 0
      position: relative
      right: auto
      float: none
      margin: 0 auto -100px 0
      width: calc( 100% )
      z-index: 0

      &::before, &:after
        display: none

  #internal-page
    #imageFeaturedProfile
      width: calc(100% + 64px)
      position: relative
      left: -32px
      bottom: 0

      .container.full-image
        max-width: none
        margin: 0
        left: 0
        position: relative
        
      .area-text
        padding: 0 32px
        --width-mobile: 8.4
    
    p.text-subtite
      line-height: 24px

    h2.subtitle
      div
        height: 30px

  #internal-page h2, h2.subtitle, #menu h5
    font-size: 38px
    line-height: 32px
  
  #internal-page h3, h3.subtitle
    font-size: 18px
    line-height: 32px
    color: #606065

  .area-black h3
    font-size: 18px
    line-height: 32px
      
  .float-image
    position: relative
    img
      max-width: 100%

  .float-image.image-right
    top: 0
    transform: none
    right: 0
    max-width: calc(100% + 32px)

  .area-black .imagesProfile.float-left .container
    max-width: calc(100% + 32px)
    margin: 0
    left: 0
    position: relative

    img
      width: 100%

  .imagesProfile.float-left .container.full
    width: calc(100% - 32px)
    left: 0

  .float-image.image-left
    top: 0
    transform: none
    left: 0
    max-width: calc(100% + 32px)
    margin-bottom: 32px

  .imagesProfile .container.full
    max-width: calc(100% + 32px)
    margin: 26px 0px 160px
    .area-image
      left: -32px
      position: relative
      margin: 0
      width: calc(100% + 64px)
      img
        width: calc(100% + 64px)
        max-width: none


  .content-profile
    padding: 40px 0

    .area-text
      --width-mobile: 10.75

    .step-portfolio
      width: 116px
      height: 74px

    &.step-one .step-portfolio
      transform: translate(-20px, -45px)

    &.step-two .step-portfolio
      transform: translate(-20px, -45px)

    &.step-three 
      padding: 200px 0 40px
      .step-portfolio
        transform: translate(-20px, -45px)

  .area-black.float-area
    padding: 40px 32px 0
    width: calc(100% + 64px)
    left: -32px
    margin-top: -279px

  .column
    --width-tablet: var(--width-mobile)
    --width: var(--width-mobile)
  
  .container, .container.full 
    max-width: 100%

  footer.footer
    position: relative
    bottom: 0px
    width: 100%
    .squares
      margin-top: 40px
    ul
      display: block
      p
        width: 100%
        margin: 40px 0
        
      li
        margin: 0
        padding: 0
        width: 100%
        display: block
        &:nth-child(2)
          text-align: left
        &:nth-child(3)
          text-align: left
  
@media (min-width:1025px) and (max-width:1280px)
  p
    font-size: 14px
    line-height: 16px
  
  p.text-featured
    font-size: 16px
    line-height: 28px
  
  h2
    font-size: 80px
    line-height: 80px
  
  h3
    font-size: 20px
    line-height: 28px
  
  h4
    font-size: 16px
    line-height: 20px
  
  h5
    font-size: 40px
    line-height: 52px
  
  blockquote p
    font-size: 36px
    line-height: 52px
  
  section
    margin: 6rem 0 0

  section#slider
    margin: 0px

  #banner-home
    margin: 0
  
  #about
    min-height: 1130px
  
  .area-flutuante
    padding: 8rem 0
  
  .column
    --width-tablet: var(--width-mobile)
    --width: var(--width-tablet)
  
  .container 
    max-width: 1024px

  .area-footer
    h3
      font-style: normal
      font-weight: 700
      font-size: 16px
      line-height: 20px
      text-align: center
      letter-spacing: 0.4em
      text-transform: uppercase

  .area-site
    .area-black.float-area footer
      padding: 0 60px 0 80px
  
@media (min-width:1281px) and (max-width:1440px)
  p
    font-size: 16px
    line-height: 20px
  
  p.text-featured
    font-size: 16px
    line-height: 28px

  h2
    font-size: 80px
    line-height: 80px

  h3
    font-size: 24px
    line-height: 28px

  h4
    font-size: 16px
    line-height: 20px

  h5
    font-size: 40px
    line-height: 52px

  blockquote p
    font-size: 36px
    line-height: 52px

  #slider
    margin: 0

  .masonry-brick
    width: calc(50% - 32px)
    margin-right: 32px

    &:nth-child(even)
      margin-left: 32px
      margin-right: 0

  #slider
    margin: 0

    .area-slider .item-area
      padding: 0

    .item-slider
      position: relative
      width: calc(50% - 32px)
      margin-right: 32px

      &:nth-child(even)
        margin-left: 32px
        margin-right: 0

    .item-area
      height: auto !important
      margin-bottom: 96px !important
      overflow: hidden

      img
        min-width: 100%

  
  #slider-interior
    margin: 0

    .area-slider .item-area
      padding: 0

    .item-slider
      position: relative
      width: calc(50% - 32px)
      margin-right: 32px

      &:nth-child(even)
        margin-left: 32px
        margin-right: 0

    .item-area
      height: auto !important
      margin-bottom: 96px !important
      overflow: hidden

      img
        min-width: 100%

  section
    margin: 6rem 0 0

  section#slider
    margin: 40px 0 0

  #banner-home
    margin: 0

  #about
    min-height: 1130px

  #internal-page
    position: relative
    z-index: 1

  #internal-page h2, h2.subtitle
    font-size: 50px
    line-height: 58px

  #internal-page h3, h3.subtitle
    font-size: 20px
    line-height: 36px
  
  .area-flutuante
    padding: 8rem 0

  .content-profile .area-text
    padding: 0 12rem
    --width-mobile: 12
    --width-tablet: 10.75
    --width-desktop: 9.5
  
  .column
    --width-tablet: var(--width-mobile)
    --width: var(--width-tablet)
  
  .container 
    max-width: 1024px

  
  .area-site
    .area-black.float-area footer
      padding: 0 60px 0 80px
  
  #menu
    .area-menu
      padding: 40px 32px 0
      overflow-y: scroll
    .embla__slide
      min-width: 340px
      &:first-child
        margin-left: 32px
      h2
        font-size: 22px
        line-height: 30px
    .embla__slide__inner
      height: 300px
    .embla__slide__img
      max-height: 300px
    .embla__progress
      left: 0
      width: calc(100% - 64px)
    .footer-menu
      bottom: 0px
      ul 
        width: 100%
    #projects-menu
      overflow: hidden
      width: calc(100% + 64px)
      left: -32px
      position: relative
      max-width: none

  .area-footer
    h3
      font-style: normal
      font-weight: 700
      font-size: 16px
      line-height: 20px
      text-align: center
      letter-spacing: 0.4em
      text-transform: uppercase

@media (min-width:1600px)

  .masonry-brick
    width: calc(50% - 32px)
    margin-right: 32px

    &:nth-child(even)
      margin-left: 32px
      margin-right: 0

  #banner-home h2 div
    height: 110px

  #banner-home
    margin: 0px

  #slider
    margin: 0

    .area-slider .item-area
      padding: 0

    .item-slider
      position: relative
      width: calc(50% - 32px)
      margin-right: 32px

      &:nth-child(even)
        margin-left: 32px
        margin-right: 0

    .item-area
      height: auto !important
      margin-bottom: 96px !important
      overflow: hidden

      img
        min-width: 100%

  
  #slider-interior
    margin: 0

    .area-slider .item-area
      padding: 0

    .item-slider
      position: relative
      width: calc(50% - 32px)
      margin-right: 32px

      &:nth-child(even)
        margin-left: 32px
        margin-right: 0

    .item-area
      height: auto !important
      margin-bottom: 96px !important
      overflow: hidden

      img
        min-width: 100%

  #about

    .area-about
      min-height: 1710px

      &:before
        height: 1050px

@media (min-width:1800px)

  #about

    .area-about
      min-height: 1910px

      &:before
        height: 1150px

@media (min-width:2000px)

  #about

    .area-about
      min-height: 2010px

      &:before
        height: 1320px