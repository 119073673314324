$white: #FFFFFF
$black: #000000
$gray: #606065
$white-border: #F2F3F3
$white-effect: #F2F2F2
$gray-shadow: #888888
$font-garamond: adobe-garamond-pro, serif
$font-helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif
  
html
  scroll-behavior: smooth

*, ::after, ::before
  box-sizing: border-box

body
  background-color: $white
  color: $black
  font-size: 100%
  font-family: $font-helvetica
  line-height: 1.5
  text-decoration: none
  overflow-x: hidden

body,html
  width: 100%
  height: 100%
  margin: 0

img
  max-width: 100%

.light-text p
  color: #B4B4B4

.light-text h5
  color: $white

.normal-style
  font-style: normal
  font-weight: normal
p
  font-family: $font-helvetica
  @extend .normal-style
  font-size: 16px
  color: $gray
  line-height: 19px
  margin: 20px 0
  &.text-featured
    font-family: $font-helvetica
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 32px

  &.text-subtite
    font-family: $font-helvetica
    color: #606065
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 32px
    margin-top: 0
    max-width: 648px

    &.font-space
      line-height: 24px

  > small
    max-width: 272px
    font-size: 10px
    line-height: 12px

h2
  font-family: $font-garamond
  @extend .normal-style
  font-size: 96px
  line-height: 96px
  margin: 0

h3
  font-family: $font-helvetica
  @extend .normal-style
  font-size: 24px
  line-height: 32px
  color: $gray

h4
  font-family: $font-helvetica
  font-style: normal
  font-weight: bold
  font-size: 16px
  line-height: 20px
  letter-spacing: 0.4em

h5
  font-family: $font-garamond
  margin-bottom: 1em
  @extend .normal-style
  font-size: 42px
  line-height: 56px
  color: $gray

h6
  font-family: $font-garamond
  @extend .normal-style
  font-size: 24px
  line-height: 32px
  color: $black
  margin: 40px 0

  strong
    @extend .normal-style
    color: $gray

blockquote
  margin: 0
  p
    font-family: $font-garamond
    @extend .normal-style
    font-size: 48px
    line-height: 64px
    color: $black
.center
  text-align: center
a
  color: $black

figcaption
  font-family: $font-helvetica
  @extend .normal-style
  font-size: 16px
  line-height: 24px
  display: flex
  align-items: center
  color: $gray

.video-container
  width: 100%
  margin: 0 auto 

.area-video
  overflow: hidden
  transition: all ease-out 0.4s
  padding-bottom: 56.25%
  position: relative
  height: 0
  transform: translateY(130px)

  &::before
    transition: all ease-out 4s
    transition-delay: 2s
    transform: translate3d(0, 0, 0)
    position: absolute
    top: 0
    left: 0
    z-index: 1
    background: $white-effect
    width: 100%
    height: 100%
    display: block
    content: ""

  &.active
    transform: translateY(0px)
    &:before
      transform: translate3d(0, -200%, 0)
    

.area-video iframe
  left: 0
  top: 0
  height: 100%
  width: 100%
  position: absolute

#internal-page h2.subtitle
  div
    background-color: transparent
    display: block
    overflow: hidden
    height: 70px
    margin-bottom: -6px
  span
    display: block
    transition: all ease-out 0.4s

#internal-page h2, h2.subtitle, #menu h5
  font-size: 56px
  line-height: 64px

#internal-page h3, h3.subtitle
  font-size: 24px
  line-height: 40px
  color: $gray

.uppercase
  text-transform: uppercase

.squares
  display: block
  margin: 0px 0 80px 0

  .square-icon
    width: 2px
    height: 2px
    margin-right: 10px
    display: inline-block
    background: $black

.loading
  height: 100%
  position: fixed
  display: block
  background: $white
  z-index: 12
  bottom: 0
  width: 100%
  overflow: hidden
  transition: 3s ease-in-out all
  text-align: center

  img
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    transition: 0.6s ease-in-out

  &.active
    height: 0%
    transition-delay: 0.4s
    img
      transform: translate(-50%, -50%)
      opacity: 0

.site
  .border-line
    position: fixed
    z-index: -2
    width: calc(100% - 80px)
    top: 0
    left: 40px
    height: 100%
    border-left: 1px solid $white-border
    border-right: 1px solid $white-border
    box-sizing: border-box

  .paddImg
    margin: 240px 0

  .border-top,.border-bottom
    display: block
    position: absolute
    top: 0px
    left: 41px
    width: calc(100% - 40px)
    height: 40px
    background:  $white
    z-index: -2
    border-bottom: 1px solid $white-border

  .border-top
    z-index: 1

  .border-bottom
    top: auto
    position: relative
    bottom: 0px
    left: auto
    right: 41px
    display: none
    z-index: 1
    width: calc(100%)
    border-bottom: none
    border-top: 1px solid $white-border
    
  &.home, &.about
    .border-bottom
      display: block

  .picture-about
    width: 100%

.area-site
  padding: 40px

.container 
  max-width: 1280px
  margin: 0 auto
  display: flex
  flex-wrap: wrap

  &.full 
    max-width: calc(100% - 160px)

  &.full-image 
    max-width: calc(100% - 80px)

.column
  --columns: 12 
  --width: var(--width-mobile, 0)
  flex-basis: calc(var(--width) / var(--columns) * 100%)
  --width-desktop: var(--width-tablet)
  --width: var(--width-desktop)

header
  --width-mobile: 12
  height: 104px
  position: relative

  &.header-home
    height: 160px

  .logo
    position: absolute
    left: 40px
    z-index: 1
    top: 40px

  .navbar-toggler
    height: 24px
    z-index: 10
    position: fixed
    outline: none
    right: 80px
    top: 82px
    width: 32px
    padding: 0
    border: none
    cursor: pointer
    background: none
    appearance: none

    .bar
      display: block
      position: absolute
      background: $black
      transition: 0.3s ease-in-out all

      &.bar-item-one
        width: 40px
        height: 4px
        top: 0
        right: 0

      &.bar-item-two
        width: 28px
        height: 4px
        top: auto
        bottom: 0
        right: 0


  &.active
    .navbar-toggler
      .bar
        &.bar-item-one
          transform: rotate(45deg) translate(10px, 0px)
          background: $white

        &.bar-item-two
          width: 40px
          transform: rotate(-45deg) translate(14px, -5px)
          background: $white

#menu
  background: $black
  overflow: hidden
  position: fixed
  z-index: 9
  width: 100%
  height: 0%
  display: block
  top: 0
  left: 0
  transition: 1.2s ease all

  &.active
    transition: 1.2s ease all
    height: 100%

    .area-menu
      opacity: 1

  .area-menu
    opacity: 0
    transition: 1.2s ease all
    position: relative
    height: 100vh
    padding: 80px

    ul
      margin: 32px 0
      width: 100%
      padding: 0

    .logo
      opacity: 0.4

    h5
      margin: 32px 0

    p, h4, .link-default
      color: $gray
      text-decoration: none

  h4, a
    color:  $white

  h4
    margin: 24px 0
    

  ul.nav
    margin: 0 0 24px
    li
      a
        text-transform: uppercase
        text-decoration: none
        letter-spacing: 0.4em
        margin: 16px 0
        display: inline-block
        font-size: 16px
        font-weight: 600
        line-height: 24px
        font-family: Helvetica Neue
        position: relative

        &:after
          content: attr(data-content)
          position: absolute
          bottom: 1px
          left: 0
          z-index: -1
          width: 95%
          height: 9px
          border-bottom: 1px solid white

      &:nth-child(1) a
        margin-top: 0

  .embla
    position: relative
    width: fit-content

  .embla__viewport
    overflow: hidden
    width: 100%

  .embla__viewport.is-draggable
    cursor: move
    cursor: grab

  .embla__viewport.is-dragging
    cursor: grabbing

  .embla__container
    display: flex
    user-select: none
    -webkit-touch-callout: none
    -khtml-user-select: none
    -webkit-tap-highlight-color: transparent

  .embla__slide
    padding-right: 24px
    min-width: 520px
    position: relative
    text-align: center
    cursor: pointer

    &:first-child
      margin-left: 80px

    h2
      position: absolute
      z-index: 2
      color: white
      font-size: 38px
      line-height: 52px
      top: 50%
      width: 80%
      left: 50%
      margin-left: -8px
      transform: translate(-50%, -50%)
      opacity: 0
      transition: 0.3s ease-in-out all
  
    &:hover
      h2
        opacity: 1
      .embla__slide__inner
        filter: grayscale(0)
      .embla__slide__img
        transform: translate(-50%, -50%) scale(1.1)

  .embla__slide__inner
    position: relative
    height: 420px
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    transition: 0.3s ease-in-out all
    filter: grayscale(0.70)

  .embla__slide__parallax
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0

  .embla__slide__img
    position: absolute
    display: block
    top: 50%
    left: 50%
    width: auto
    min-height: 100%
    min-width: 100%
    max-width: none
    max-height: 440px
    transition: 0.7s ease-in-out all
    transform: translate(-50%, -50%)

  .embla__button
    outline: 0
    display: none
    cursor: pointer
    background-color: transparent
    touch-action: manipulation
    position: absolute
    z-index: 1
    top: 50%
    transform: translateY(-50%)
    border: 0
    width: 30px
    height: 30px
    justify-content: center
    align-items: center
    fill: #1bcacd
    padding: 0

  .embla__button:disabled
    cursor: default
    opacity: 0.3

  .embla__button__svg
    width: 100%
    height: 100%

  .embla__button--prev
    left: 27px

  .embla__button--next
    right: 27px

  .embla__progress
    position: relative
    background-color: $gray
    margin-top: 20px
    height: 1px
    overflow: hidden
    margin-left: auto
    margin-right: auto
    width: calc(100% + 40px)
    left: 80px

  .embla__progress__bar
    position: absolute
    background-color: $white
    height: 1px
    width: 100%
    top: 0
    bottom: 0
    left: -100%        

  #projects-menu
    width: calc(100% - 40px)
    left: -80px
    position: relative
    transition: 1s ease all
    li
      float: left
      margin-right: 40px
      img
        width: 310px

  .footer-menu
    position: absolute
    bottom: 60px
    width: calc(100% - 80px)


    .icon-footer
      position: relative
      top: 4px
      display: inline-block
      padding: 0 14px
      left: -20px
      margin-right: -20px

    ul
      display: flex
      width: calc(100% - 80px)
      p
        width: 100%
        line-height: 24px
        
      li
        margin: 0
        padding: 0
        width: 33.33%
        display: flex
        align-items: center
        justify-content: left
        &:nth-child(2)
          text-align: center
        &:nth-child(3)
          text-align: right

        a.link-default
          text-decoration: underline
          color: $white

          &.link-separate
            margin: 0 20px
            position: relative

            &::before
              position: absolute
              content: "•"
              color: $gray
              left: -12px

            &::after
              position: absolute
              color: $gray
              content: "•"
              right: -13px

              
      
    .square-icon
      background:  $white

section
  margin: 120px 0 0
  position: relative

  &.no-top
    margin-top: 0

.image-effect
  position: relative
  transition: all ease-out 0.2s
  overflow: hidden

  // &.image-delay
  //   img, &::before
  //     transition-delay: 2s
  // &.image-delay-1
  //   img, &::before
  //     transition-delay: 1s
  img
    transition: all ease-in-out 1s
    transform: translate3d(0, 100px, 0)
  a
    text-decoration: none
    p
      opacity: 0
      transition: all ease-in-out 1s
      margin: 12px 40px
      font-weight: 900
      color: #000000

      span
        font-weight: 400
        color: #707070

  &.black-effect
    &::before
      background: $white-effect

  &::before
    transition: all ease-out 3s
    transform: translate3d(0, 0, 0)
    position: absolute
    top: 0
    left: 0
    z-index: 1
    background: $white-effect
    width: 100%
    height: 100%
    display: block
    content: ""
    
  &.active
    p
      opacity: 1
    &:before
      transform: translate3d(0, -180%, 0)
    img
      transform: translate3d(0, 0, 0)

#banner-home
  margin: 0 0 120px
  position: relative
  z-index: 1
  h3
    margin: 8px 0 0
    transition: all ease-out 1s

  h2
    div
      background-color: transparent
      display: block
      overflow: hidden
      height: 90px
      margin-bottom: -6px
    span
      display: block
      transition: all ease-out 0.4s

  .area-banner
    text-align: center
    --width-mobile: 12
    --width-tablet: 12
    --width-desktop: 12
    
    .area-filter
      margin-top: 72px
      text-align: center
      display: flex
      align-items: center
      justify-content: center

      .filter-itens
        padding: 0
        margin: 0
        display: flex

        li
          list-style: none

          button
            text-transform: uppercase
            background: transparent
            border: none
            margin: 0 12px
            -webkit-appearance: none
            cursor: pointer
            p
              letter-spacing: 0.08em
              font-weight: 700

          &.active
            button p
              color: $black
              position: relative

              &::before
                content: ""
                background: $black
                position: absolute
                bottom: 0
                height: 1px
                display: block
                width: 100%
                left: 0px

#slider
  .area-slider
    --width-mobile: 12
    --width-tablet: 12
    --width-desktop: 12
    display: flex
    flex-wrap: wrap

    .item-slider
      position: relative

    .item-area
      overflow: hidden

      img
        min-width: 100%

    .step-portfolio
      display: block
      width: 190px
      height: 190px
      bottom: 50px
      right: 50px
      position: absolute
      background-size: contain
      background-repeat: no-repeat
      z-index: 2
      transition: 1s ease-in-out all

    // #case1
    //   .step-portfolio
    //     background-image: url(../img/number-one.png)
    //     width: 170px
    // #case2
    //   .step-portfolio
    //     background-image: url(../img/number-two.png)
    // #case3
    //   .step-portfolio
    //     background-image: url(../img/number-three-white.png)
    // #case4
    //   .step-portfolio
    //     background-image: url(../img/number-four.png)
    // #case5
    //   .step-portfolio
    //     background-image: url(../img/number-five.png)
    // #case6
    //   .step-portfolio
    //     background-image: url(../img/number-six.png)
    // #case7
    //   .step-portfolio
    //     background-image: url(../img/number-seven.png)
    // #case8
    //   .step-portfolio
    //     background-image: url(../img/number-eight.png)

  .square-effect
    position: absolute
    width: 624px
    height: 296px
    right: 50%
    top: 50%
    transform: translate(140%, -100%)
    z-index: -1
    border: 1px solid $white-border
    box-sizing: border-box

#testimonial
  padding: 120px 0 240px
  .area-testimonial
    --width-mobile: 9
    --width-tablet: 9
    --width-desktop: 9
    margin: 0 auto
    left: -55px
    position: relative

  .embla
    position: relative
    max-width: 736px
    margin-left: auto
    margin-right: auto

  .embla__viewport
    overflow: hidden
    width: 100%

  .embla__viewport.is-draggable
    cursor: move
    cursor: grab

  .embla__viewport.is-dragging
    cursor: grabbing

  .embla__container
    display: flex
    user-select: none
    -webkit-touch-callout: none
    -khtml-user-select: none
    -webkit-tap-highlight-color: transparent

  .embla__slide
    position: relative
    min-width: 100%

    u
      &:after
        transition: 0.4s ease-in-out all
        transition-delay: 0.5s
        opacity: 0.2
        width: 0
        left: 50%
        transform: translateX(-50%)
      &:nth-child(2):after
        transition-delay: 1s
      
    &.is-selected
      u:after
        width: 100%
        opacity: 1

  .embla__slide__inner
    position: relative
    overflow: hidden

  .embla__slide__img
    position: absolute
    display: block
    top: 50%
    left: 50%
    width: auto
    min-height: 100%
    min-width: 100%
    max-width: none
    transform: translate(-50%, -50%)

  .embla__button
    display: none
    outline: 0
    cursor: pointer
    background-color: transparent
    touch-action: manipulation
    position: absolute
    z-index: 1
    top: 50%
    transform: translateY(-50%)
    border: 0
    width: 30px
    height: 30px
    justify-content: center
    align-items: center
    fill: #1bcacd
    padding: 0

  .embla__button:disabled
    cursor: default
    opacity: 0.3

  .embla__button__svg
    width: 100%
    height: 100%

  .embla__button--prev
    left: 27px

  .embla__button--next
    right: 27px

  .embla__dots
    display: flex
    list-style: none
    justify-content: flex-end
    padding-top: 10px
    max-width: 726px
    margin-left: auto
    margin-right: auto

  .embla__dot
    background-color: transparent
    cursor: pointer
    position: relative
    padding: 0
    outline: 0
    border: 0
    width: 8px
    height: 8px
    margin-right: 4px
    margin-left: 4px
    display: flex
    align-items: center

  .embla__dot:after
    border: 1px solid #E0E0E0
    background-color: #F2F3F3
    width: 100%
    height: 8px
    border-radius: 0px
    content: ""

  .embla__dot.is-selected:after
    border: 1px solid $gray
    background-color: $gray
    opacity: 1

#about
  position: relative
  z-index: 0
  &:after 
    content: ""
    display: table
    clear: both

  .area-about
    min-height: 1610px
    --width-mobile: 12
    --width-tablet: 12
    --width-desktop: 12

    &:after
      content: ""
      background: $black
      position: absolute
      top: 400px
      left: 0
      width: 100%
      height: 890px
      display: block
      width: 100%
      border-left: 1px solid rgba($gray-shadow, .3)
      border-right: 1px solid rgba($gray-shadow, .3)
      z-index: -1

    &:before
      content: ""
      background: $black
      position: absolute
      top: 400px
      z-index: -2
      height: 890px
      display: block
      width: calc(100% + 80px)
      left: -40px

    #team-area
      margin: 40px auto
      max-width: 2000px
      display: flex
      justify-content: center
      .item-team
        margin: 15px
        width: 25%

        h6
          color: $white
          margin: 24px 0 0
          
        p 
          margin: 8px 0
          small
            font-weight: bold
            color: $gray
            letter-spacing: 0.4em
            max-width: 280px
            display: block
            margin: 0 auto

    #team-area-2
      margin: 40px auto
      max-width: 2000px
      display: flex
      justify-content: center
      .item-team
        margin: 15px
        width: 25%

        h6
          color: $white
          margin: 24px 0 0
          
        p 
          margin: 8px 0
          small
            font-weight: bold
            color: $gray
            letter-spacing: 0.4em
            max-width: 280px
            display: block
            margin: 0 auto

  .area-text
    padding: 0 8rem
    --width-mobile: 12
    --width-tablet: 9
    --width-desktop: 10.8

  .container.full 
    max-width: calc(100% - 80px)

  #header-Featured
    --width-mobile: 12
    --width-tablet: 12
    --width-desktop: 12
    margin-bottom: 120px
    display: flex
    justify-content: space-between
    .area-column
      margin: 40px
      max-width: 648px
      &.title-area
        margin: 0
        max-width: 240px

  .embla
    position: relative
    width: fit-content

  .embla__viewport
    overflow: hidden
    width: 100%

  .embla__viewport.is-draggable
    cursor: move
    cursor: grab

  .embla__viewport.is-dragging
    cursor: grabbing

  .embla__container
    display: flex
    user-select: none
    -webkit-touch-callout: none
    -khtml-user-select: none
    -webkit-tap-highlight-color: transparent

  .embla__slide
    padding-right: 24px
    min-width: 520px
    position: relative
    text-align: center
    cursor: pointer

    &:first-child
      margin-left: 80px

    h2
      position: absolute
      z-index: 2
      color: white
      font-size: 38px
      line-height: 52px
      top: 50%
      width: 80%
      left: 50%
      margin-left: -8px
      transform: translate(-50%, -50%)
      opacity: 0
      transition: 0.3s ease-in-out all
  
    &:hover
      h2
        opacity: 1
      .embla__slide__inner
        filter: grayscale(0)
      .embla__slide__img
        transform: translate(-50%, -50%) scale(1.1)

  .embla__slide__inner
    position: relative
    height: 420px
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    transition: 0.3s ease-in-out all
    filter: grayscale(0.70)

  .embla__slide__parallax
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0

  .embla__slide__img
    position: absolute
    display: block
    top: 50%
    left: 50%
    width: auto
    min-height: 100%
    min-width: 100%
    max-width: none
    max-height: 440px
    transition: 0.7s ease-in-out all
    transform: translate(-50%, -50%)

  .embla__button
    outline: 0
    display: none
    cursor: pointer
    background-color: transparent
    touch-action: manipulation
    position: absolute
    z-index: 1
    top: 50%
    transform: translateY(-50%)
    border: 0
    width: 30px
    height: 30px
    justify-content: center
    align-items: center
    fill: #1bcacd
    padding: 0

  .embla__button:disabled
    cursor: default
    opacity: 0.3

  .embla__button__svg
    width: 100%
    height: 100%

  .embla__progress
    display: none

  #projects
    margin-top: -200px
    padding-left: 80px
    width: 100%
    overflow: hidden
    width: calc(100% + 80px)
    left: -40px

    p, h4, .link-default
      color: $gray
      text-decoration: none

    .area-carousel-projects
      transition: 1s ease-in-out all
      opacity: 0

      &.active
        opacity: 1

    #projects-about
      width: calc(100% - 40px)
      left: -80px
      position: relative
      transition: 1s ease all
      li
        float: left
        margin-right: 40px
        img
          width: 310px

#imageFeaturedProfile
  img
    align-self: flex-start
    width: 100%

  &.wide
    iframe
      height: 88.9%

  iframe
    align-self: flex-start
    width: 100%
    height: 100%

  .area-text
    padding: 0 8rem
    --width-mobile: 12
    --width-tablet: 9
    --width-desktop: 8.4

    h6
      margin-bottom: 20px

.content-profile .area-text
  padding: 0 4rem
  --width-mobile: 12
  --width-tablet: 10.75
  --width-desktop: 7.2


#testimonial .area-testimonial figure
  margin: 0
  padding: 64px 0 16px 96px
  position: relative

.quote-icon
  position: absolute
  top: 65px
  left: 10px

u
  position: relative
  text-decoration: none

  &:after
    content: attr(data-content)
    position: absolute
    bottom: 8px
    left: 0
    z-index: -1
    width: 100%
    height: 9px
    background-color: #F7E2CD

.content-profile
  padding: 160px 0 120px

  .fade-active
    opacity: 0
    transition: 1.5s ease-in-out all

    &.active
      opacity: 1
  
  .step-portfolio
    display: block
    width: 300px
    height: 300px
    position: absolute
    background-size: contain
    background-repeat: no-repeat
    z-index: -1
    opacity: 0
    transition: 1s ease-in-out all

    &.active
      opacity: 1

  &.step-one .step-portfolio
    // background-image: url(../img/number-one.png)
    transform: translate(-170px, -80px)

  &.step-two
    position: relative
    z-index: 3
    .step-portfolio
      // background-image: url(../img/number-two.png)
      transform: translate(500px, -50px)
      .area-text
        padding: 0
        --width-mobile: 12
        --width-tablet: 6.2
        --width-desktop: 6

  &.step-three
    padding: 12rem 0
    .step-portfolio
      // background-image: url(../img/number-three.png)
      transform: translate(-120px, -70px)

.area-black
  background: $black
  h2
    color: $white
  h3
    color: $gray-shadow
  h5
    color: $gray-shadow  
  
  .icon-footer svg path
    fill: white

  &.float-area
    z-index: 0
    padding: 180px 0 0
    width: calc(100% + 8rem)
    position: relative
    left: -4.8rem
    bottom: 0
    margin-top: -100px
    margin-bottom: -100px

  footer
    p
      color: $gray-shadow
    h4, a
      color:  $white

    ul li a.link-default
      color: $white

  .square-icon
    background:  $white
  

  .imagesProfile.float-left
    .container
      max-width: calc(100% + 160px)
      margin: 0
      left: 0
      position: relative
    .area-image
      --width-mobile: 12
      --width-tablet: 12
      --width-desktop: 12

      img
        width: 100%

.imagesProfile.float-left .area-image
  margin: 0

.float-image
  position: absolute
  top: -25%
  z-index: 1
  right: -80px
  max-width: 658px

  &.image-right
    top: -25%
    right: -50px

  &.image-left
    top: -15%
    left: 80px

.imagesProfile
  .area-image
    --width-mobile: 12
    --width-tablet: 12
    --width-desktop: 10
    margin: 0 auto

  &.float-left
    z-index: 2

    .container
      max-width: calc(100% - 160px)
      margin: 0
      left: -50px
      position: relative

.footer
  padding: 80px 0 120px
  .area-footer
    width: 100%
    h3
      color: $black
      margin-bottom: 80px
    .icon-footer
      position: relative
      top: 4px
      display: inline-block
      padding: 0 14px
      left: -20px
      margin-right: -20px

  ul
      display: flex
      width: 100%
      padding: 0
      p
        width: 100%
        line-height: 24px
        
      li
        margin: 0
        padding: 0
        width: 33.33%
        display: flex
        align-items: center
        justify-content: left
        &:nth-child(2)
          text-align: center
        &:nth-child(3)
          text-align: right

        a.link-default
          text-decoration: underline
          color: $black

          &.link-separate
            margin: 0 20px
            position: relative

            &::before
              position: absolute
              content: "•"
              color: $gray
              left: -12px

            &::after
              position: absolute
              color: $gray
              content: "•"
              right: -13px


#slider-interior
  .area-slider
    --width-mobile: 12
    --width-tablet: 12
    --width-desktop: 12
    display: flex
    flex-wrap: wrap

    .item-slider
      position: relative

    .item-area
      overflow: hidden

      img
        min-width: 100%

    .step-portfolio
      display: block
      width: 190px
      height: 190px
      bottom: 50px
      right: 50px
      position: absolute
      background-size: contain
      background-repeat: no-repeat
      z-index: 2
      transition: 1s ease-in-out all

  .square-effect
    position: absolute
    width: 624px
    height: 296px
    right: 50%
    top: 50%
    transform: translate(140%, -100%)
    z-index: -1
    border: 1px solid $white-border
    box-sizing: border-box


.masonry
  display: flex
  width: 100%

.masonry-brick
  flex: auto
  overflow: hidden
  margin-bottom: 30px

.area-filtro
  opacity: 0
  transition: all ease-out 0.4s
  height: 0
  display: block
  overflow: hidden

  &.active
    opacity: 1
    height: auto
    overflow: visible
    transition: all ease-out 0.4s
    display: block

@import '_media.sass'